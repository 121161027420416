import { ElementType, lazy, Suspense } from "react";
import {
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
  useRoutes,
} from "react-router-dom";
import useAuth from "src_common/hooks/useAuth";
import MainLayout from "src_common/layouts/main";
import LogoOnlyLayout from "src_common/layouts/LogoOnlyLayout";
import GuestGuard from "src_common/guards/GuestGuard";
import LoadingScreen from "src_common/components/LoadingScreen";
import AuthLayout from "src_common/layouts/AuthLayout";
import AuthGuard from "src_common/guards/AuthGuard";
import { PATH_HOME, PATH_POST_HANDLING } from "./paths";
import navConfig from "./nav-config";
import SingleMatterLayout from "src_lawfirm/pages/matters/SingleMatterLayout";
import ContactEmbeded from "src_lawfirm/pages/contacts/ContactEmbeded";
import LedgersTab from "src_lawfirm/pages/matters/LedgersTab";
import AnticipatedTab from "src_lawfirm/pages/matters/AnticipatedTab";
import SetPassword from "src_lawfirm/pages/auth/SetPassword";
import TimeTab from "src_lawfirm/pages/matters/TimeTab";
import EditMatterPage from "src_lawfirm/pages/matters/EditMatterPage";
import { PATH_AUTH } from "src_common/routes/paths";
import { CanvasContextProvider } from "src_lawfirm/pages/workflow/canvas-context";
import { AddMenuContextProvider } from "src_common/contexts/AddMenuContext";
import FormReplyEmbedded from "src_lawfirm/pages/forms/ReplyEmbeded";
import { MenuContextProvider } from "src_common/components/menu/menu-context";
import { PostOfficeWatcher } from "src_lawfirm/pages/post-office/PostOfficeWatcher";
import { PostOfficeContextProvider } from "../pages/post-office/post-office-context";
import { MatterDetailsContextProvider } from "../pages/matters/context/matter-details-context";
import { WopiContextProvider } from "../../src_common/contexts/WopiContext";
import SignDocumentsPage from '../pages/sign/documents'

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isAuthenticated } = useAuth();

  const isDashboard = pathname.includes("/dashboard") && isAuthenticated;

  return <Component {...props} />;
};

const HandleLogout = () => {
  const navigate = useNavigate();
  const { logout } = useAuth();

  logout();
  navigate(PATH_AUTH.login, { replace: true });
  return null;
};

export default function Router() {
  return useRoutes([
    {
      path: "auth",
      element: (
        <Suspense fallback={<LoadingScreen isDashboard={false} />}>
          <GuestGuard>
            <AuthLayout label="You Are Organized" />
          </GuestGuard>
        </Suspense>
      ),
      children: [
        { path: "login", element: <LoginPage />, index: true },
        { path: "reset-password", element: <ResetPassword /> },
        { path: "new-password", element: <NewPassword /> },
        { path: "set-password", element: <SetPassword /> },
      ],
    },
    {
      path: "forms",
      element: (
        <Suspense fallback={<LoadingScreen isDashboard={false} />}>
          <GuestGuard>
            <AuthLayout label="You Are Organized" />
          </GuestGuard>
        </Suspense>
      ),
      children: [
        { path: "contact/:token", element: <ContactEmbeded /> },
        { path: "document/:token", element: <FormReplyEmbedded /> },
      ],
    },
    {
      path: "sign",
      element: (
        <Suspense fallback={<LoadingScreen isDashboard={false} />}>
          <GuestGuard>
            <AuthLayout label="You Are Organized" />
          </GuestGuard>
        </Suspense>
      ),
      children: [
        {
          path: "document",
          element: (
            <WopiContextProvider>
              <SignDocumentsPage />
            </WopiContextProvider>
          ),
        },
      ],
    },

    // Main Routes
    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [
        { path: "coming-soon", element: <ComingSoon /> },
        { path: "maintenance", element: <Maintenance /> },
        { path: "500", element: <Page500 /> },
        { path: "404", element: <Page404 /> },
        { path: "403", element: <Page403 /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
    // Main routes
    {
      path: "/",
      element: (
        <AuthGuard>
          <AddMenuContextProvider>
            <MenuContextProvider>
              <WopiContextProvider>
                <PostOfficeWatcher>
                  <MainLayout navConfig={navConfig} />
                </PostOfficeWatcher>
              </WopiContextProvider>
            </MenuContextProvider>
          </AddMenuContextProvider>
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_HOME.root} replace />, index: true },
        { path: "home", element: <HomePage /> },
        {
          path: "attorneys",
          element: <AttorneysPage />,
          children: [
            { path: "list", element: <AttorneysList /> },
            { path: "xero", element: <XeroAttorneysPage /> },
          ],
        },
        {
          path: "tasks",
          element: <TaskPage />,
        },
        {
          path: "calendar",
          element: <ComingSoon />,
        },
        {
          path: "matters/new",
          element: (
            <MatterDetailsContextProvider>
              <NewMatterPage />
            </MatterDetailsContextProvider>
          ),
        },
        {
          path: "matters/:matterId/edit",
          element: (
            <MatterDetailsContextProvider>
              <EditMatterPage />
            </MatterDetailsContextProvider>
          ),
        },
        {
          path: "matters/:matterId",
          element: (
            <MatterDetailsContextProvider>
              <SingleMatterLayout />
            </MatterDetailsContextProvider>
          ),
          children: [
            { path: "overview", element: <OverviewTab /> },
            { path: "tasks", element: <TasksListTab /> },
            { path: "correspondence", element: <CorrespondencesTab /> },
            { path: "time", element: <TimeTab /> },
            {
              path: "accounts",
              element: <AccountInMatterLayout />,
              children: [
                { path: "ledgers", element: <LedgersTab /> },
                { path: "invoices", element: <InvoicesTab /> },
                { path: "payments", element: <PaymentsTab /> },
                { path: "anticipated", element: <AnticipatedTab /> },
              ],
            },
            {
              path: "forms",
              element: <FormsTab />,
            },
            {
              path: "progress",
              element: <WorkflowTab />,
            },
            {
              path: "compliance",
              element: <ComplianceTab />,
            },
            { path: "*", element: <ComingSoon /> },
          ],
        },
        {
          path: "matters/payment-notification-menu",
          element: <PaymentNotificationMenu />,
        },
        {
          path: "matters",
          element: <MattersPage />,
        },
        {
          path: "contacts",
          element: <ContactsPage />,
        },
        {
          path: "accounting/statement/:id",
          element: <StatementDetailsPage />,
        },
        {
          path: "accounting",
          element: <AccountingPage />,
          children: [
            { path: "statements", element: <StatementPage /> },
            { path: "reconciliation", element: <ReconcilePage /> },
            { path: "accounts", element: <BankAccountPage /> },
            {
              path: "payment-notifications",
              element: <PaymentNotificationsPage />,
            },
            { path: "xero", element: <XeroPage /> },
            { path: "posting", element: <PostingsPage /> },
            { path: "invoices", element: <InvoicePage /> },
            { path: "anticipated", element: <AnticipatedPage /> },
            { path: "batch-payments", element: <BatchPaymentPage /> },
          ],
        },
        {
          path: "configuration",
          element: <ConfigurationPage />,
        },
        {
          path: "configuration/narratives",
          element: <NarrativesTab />,
        },
        {
          path: "forms-template",
          element: <FormsPage />,
        },
        {
          path: "workflow-template",
          element: <Outlet />,
          children: [
            {
              path: "canvas/:id",
              element: (
                <CanvasContextProvider>
                  <WorkflowCanvasPage />
                </CanvasContextProvider>
              ),
            },
            { path: "", element: <WorkflowPage /> },
          ],
        },
        {
          path: "templates",
          element: <TemplatesPage />,
          children: [
            {
              path: "precedents",
              element: <PrecedentsPage />,
            },
            {
              path: "emails",
              element: <EmailsPage />,
            },
          ],
        },
        {
          path: PATH_POST_HANDLING.root,
          element: (
            <PostOfficeContextProvider>
              <PostOfficePage />
            </PostOfficeContextProvider>
          ),
        },
        {
          path: "help-and-support",
          element: <ComingSoon />,
        },
        {
          path: "time-entries",
          element: <TimeEntriesPage />,
        },
        {
          path: "exit",
          element: <Exit />,
        },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const LoginPage = Loadable(
  lazy(() => import("src_lawfirm/pages/auth/LoginPage"))
);
const ResetPassword = Loadable(
  lazy(() => import("src_lawfirm/pages/auth/ResetPassword"))
);
const NewPassword = Loadable(
  lazy(() => import("src_lawfirm/pages/auth/NewPassword"))
);

// Main
const HomePage = Loadable(lazy(() => import("src_lawfirm/pages/Home")));
const AttorneysPage = Loadable(
  lazy(() => import("src_lawfirm/pages/attorneys"))
);
const AttorneysList = Loadable(
  lazy(() => import("src_lawfirm/pages/attorneys/AttorneysList"))
);
const XeroAttorneysPage = Loadable(
  lazy(() => import("src_lawfirm/pages/attorneys/XeroAttorneysPage"))
);
const MattersPage = Loadable(
  lazy(() => import("src_lawfirm/pages/matters/MattersListPage"))
);
const TasksListTab = Loadable(
  lazy(() => import("src_lawfirm/pages/matters/TaskListTab"))
);
const CorrespondencesTab = Loadable(
  lazy(() => import("src_lawfirm/pages/matters/CorrespondenceTab"))
);
const OverviewTab = Loadable(
  lazy(() => import("src_lawfirm/pages/matters/OverviewTab"))
);
const NewMatterPage = Loadable(
  lazy(() => import("src_lawfirm/pages/matters/NewMatterPage"))
);
const PaymentNotificationMenu = Loadable(
  lazy(() => import("src_lawfirm/pages/matters/PaymentNotificationMenu"))
);
const BankAccountPage = Loadable(
  lazy(() => import("src_lawfirm/pages/bank-account"))
);
const XeroPage = Loadable(
  lazy(() => import("src_lawfirm/pages/bank-account/XeroPage"))
);
const PaymentNotificationsPage = Loadable(
  lazy(() => import("src_lawfirm/pages/payment-notifications"))
);
const PostingsPage = Loadable(
  lazy(() => import("src_lawfirm/pages/payment-notifications/Postings"))
);
const AnticipatedPage = Loadable(
  lazy(() => import("src_lawfirm/pages/payment-notifications/Anticipated"))
);

const InvoicePage = Loadable(
  lazy(() => import("src_lawfirm/pages/invoices/index"))
);
const ContactsPage = Loadable(lazy(() => import("src_lawfirm/pages/contacts")));
const TimeEntriesPage = Loadable(
  lazy(() => import("src_lawfirm/pages/time-entries"))
);

const InvoicesTab = Loadable(
  lazy(() => import("src_lawfirm/pages/matters/Invoices/InvoicesTab"))
);
const PaymentsTab = Loadable(
  lazy(() => import("src_lawfirm/pages/matters/Payments/PaymentsTab"))
);
const AccountInMatterLayout = Loadable(
  lazy(() => import("src_lawfirm/pages/matters/AccountInMatterLayout"))
);

const AccountingPage = Loadable(
  lazy(() => import("src_lawfirm/pages/accounting"))
);
const StatementPage = Loadable(
  lazy(() => import("src_lawfirm/pages/statements"))
);
const StatementDetailsPage = Loadable(
  lazy(() => import("src_lawfirm/pages/statements/details"))
);
const ReconcilePage = Loadable(
  lazy(() => import("src_lawfirm/pages/reconciliation/reconcile"))
);
const FormsPage = Loadable(lazy(() => import("src_lawfirm/pages/forms")));
const FormsTab = Loadable(
  lazy(() => import("src_lawfirm/pages/matters/FormsTab"))
);
const PrecedentsPage = Loadable(
  lazy(() => import("src_lawfirm/pages/precedents"))
);
const TemplatesPage = Loadable(
  lazy(() => import("src_lawfirm/pages/precedents/root"))
);
const EmailsPage = Loadable(
  lazy(() => import("src_lawfirm/pages/precedents/emails"))
);
const WorkflowPage = Loadable(lazy(() => import("src_lawfirm/pages/workflow")));
const WorkflowCanvasPage = Loadable(
  lazy(() => import("src_lawfirm/pages/workflow/canvas"))
);
const WorkflowTab = Loadable(
  lazy(() => import("src_lawfirm/pages/matters/WorkflowTab"))
);
const ComplianceTab = Loadable(
  lazy(() => import("src_lawfirm/pages/matters/Compliance/ComplianceTab"))
);

const BatchPaymentPage = Loadable(
  lazy(() => import("src_lawfirm/pages/payment-notifications/BatchPayment"))
);

const PostOfficePage = Loadable(
  lazy(() => import("src_lawfirm/pages/post-office"))
);

const TaskPage = Loadable(
  lazy(() => import("src_lawfirm/pages/tasks/TaskPage"))
);

const NarrativesTab = Loadable(
    lazy(()=> import("src_lawfirm/pages/configuration/narrative/NarrativesTab"))
)

const ConfigurationPage = Loadable(
    lazy(() => {
      return import("src_lawfirm/pages/configuration/ConfigurationPage");
    })
)
// MISC
const ComingSoon = Loadable(lazy(() => import("src_common/pages/ComingSoon")));
const Maintenance = Loadable(
  lazy(() => import("src_common/pages/Maintenance"))
);

const Exit = HandleLogout;
const Page500 = Loadable(lazy(() => import("src_common/pages/Page500")));
const Page403 = Loadable(lazy(() => import("src_common/pages/Page403")));
const Page404 = Loadable(lazy(() => import("src_common/pages/Page404")));
